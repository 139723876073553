import {
  Button,
  Container,
  Card,
  Table,
  Modal,
} from "react-bootstrap";
import Loading from "components/Loading.js";

import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

import 'assets/css/account-upgrade.css';
import { Get, Post } from "api";

function Paypal({ plan, account }) {
  const auth = useAuth0();
  return (<div><PayPalButtons
    createSubscription={(data, actions) => {
      return actions.subscription
        .create({
          plan_id: plan.paypalPlanId,
          custom_id: "thisshouldencodeuserid",
        })
        .then(async (orderId) => {
          console.log('got subscription id ', orderId);
          return orderId
        })
    }}
    onCancel={async (data) => {
    }}
    onApprove={async (data, actions) => {
      console.log('You have successfully created subscription ' + data.subscriptionID);
      console.log(data);
      await Post(
        auth,
        "/v1/account/subscription/create",
        { "paypal_subscription_id": data.subscriptionID, "account_package_id": plan.id, "paypal_order_id": data.orderID });
      await account.reload();
    }}
    style={{
      shape: 'pill',
      color: 'blue',
      layout: 'vertical',
      label: 'subscribe'
    }}
  /></div>);
}

function UpgradeButton({ account, plan }) {
  const [clicked, setClicked] = useState(false);

  // If we are looking at the 'current' plan then there's no work to be done.
  if ((account.accountPackage != null && account.accountPackage.package.id == plan.id) ||
    (account.accountPackage == null && plan.name == "Free Plan")) {
    return <Button>Current Plan</Button>
  }

  if (!clicked) {
    var text = "Upgrade";
    if (account.accountPackage != null && parseInt(account.accountPackage.package.cost) > parseInt(plan.cost)) {
      text = "Downgrade";
    }
    return <Button onClick={async () => {
      setClicked(true);
      // If we already have a subscription and the button was pressed.
      // This is up here because of limitations with useEffect. it cannot be after any branches.
      if (account.accountPackage != null && account.accountPackage.state == 'ACTIVE') {
        console.log('sending update request');
        const response = await Post(
          auth,
          "/v1/account/subscription/update",
          {
            "paypal_plan_id": plan.paypalPlanId,
            "plan_id": plan.id
          })
        console.log('update responded with, ', response);
        if (response.paypalConfirmationUrl) {

        }
      }
    }}>{text}</Button>;
  }

  // We only display the paypal flow if the user does not have an active subscription.
  if (account.accountPackage == null || account.accountPackage.state == 'PENDING') {
    return <Paypal account={account} plan={plan} />;
  } else {
    return <Button>Upgrading...</Button>;
  }
}

function AccountUpgrade({ account, packages }) {
  // better mapping to package names.
  for (var i in packages) {
    packages[packages[i].name] = packages[i];
  }

  console.log('account', account);
  if (account.accountPackage != null && account.accountPackage.state == 'PENDING') {
    return <Modal show={true} onHide={() => { }}>
      <Modal.Header>Waiting for pending paypal transaction</Modal.Header>
      <Modal.Body>There was an ongoing paypal transaction that we are awaiting the results for.
        Contact support if this dialog does not go away support@rliable.com</Modal.Body>
    </Modal>;
  }

  return (
    <Container fluid>
      <h1 style={{ textAlign: "center" }}>Upgrade Your Account</h1>
      <Card>
        <Card.Body>
          <Table className="accountUpgrade" style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th></th>
                <th>
                  <h3>Free</h3>
                  <div className="price-container">
                    <span className="dollar">$</span><span className="price">0</span> / mo
                  </div>
                  <UpgradeButton account={account} plan={packages["Free Plan"]} />
                  <div></div>
                </th>
                <th>
                  <h3>Startup</h3>
                  <div className="price-container">
                    <span className="dollar">$</span><span className="price" >7 </span>/ mo
                  </div>
                  <UpgradeButton account={account} plan={packages["Startup Plan"]} />
                </th>
                <th>
                  <h3>Pro</h3>
                  <div className="price-container">
                    <span className="dollar">$</span><span className="price">70</span> / mo
                  </div>
                  <UpgradeButton account={account} plan={packages["Pro Plan"]} />
                </th>
                <th>
                  <h3>Enterprise</h3>
                  <div className="price-container">
                    <span className="dollar">$</span><span className="price">500</span> / mo
                  </div>
                  <UpgradeButton account={account} plan={packages["Enterprise Plan"]} />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="featureName">Storage</th>
                <th>100 MiB</th>
                <th>1 GiB</th>
                <th>10 GiB</th>
                <th>100 GiB</th>
              </tr>
              <tr>
                <th className="featureName">Rliable Multi-Region Storage</th>
                <th>
                  <div>—</div>
                </th>
                <th>
                  <div className="tick">✓</div>
                </th>
                <th>
                  <div className="tick">✓</div>
                </th>
                <th>
                  <div className="tick">✓</div>
                </th>
              </tr>
              <tr>
                <th className="featureName">Encryption at Rest</th>
                <th>
                  <div>—</div>
                </th>
                <th>
                  <div className="tick">✓</div>
                </th>
                <th>
                  <div className="tick">✓</div>
                </th>
                <th>
                  <div className="tick">✓</div>
                </th>
              </tr>
              <tr>
                <th className="featureName">Binlog Streaming</th>
                <th>
                  <div>—</div>
                </th>
                <th>
                  <div>-</div>
                </th>
                <th>
                  <div className="tick">✓</div>
                </th>
                <th>
                  <div className="tick">✓</div>
                </th>
              </tr>
              <tr>
                <th className="featureName">Backup Frequency</th>
                <th>
                  <div>Up to Daily</div>
                </th>
                <th>
                  <div>Up to Hourly</div>
                </th>
                <th>
                  <div>Up to Once a Minute</div>
                </th>
                <th>
                  <div>Up to Once a Minute</div>
                </th>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
}

function AccountUpgradeLayout({ account }) {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const auth = useAuth0();

  useEffect(async () => {
    const packages = (await Get(auth, "/v1/account/packages")).packages;
    setPackages(packages);
    setLoading(false);
  }, []);


  if (account == null || packages == null || loading) return <Loading />;

  return (
    <div className="content">
      <PayPalScriptProvider options={{
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        components: 'buttons',
        vault: true,
        intent: "subscription",
      }}>
        <AccountUpgrade account={account} packages={packages} />
      </PayPalScriptProvider>
    </div>
  );
}

export default AccountUpgradeLayout;