import { useEffect, useReducer } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Get, Post } from "api.js";
import { useForm } from "react-hook-form";

import { ConnectionForm, connectionInitialState, connectionReducer } from "components/DatabaseConnection";

import Loading from "components/Loading";
const {
  Alert,
  Button,
  Card,
  Form,
  Spinner,
  Table,
  Row,
  Col,
} = require("react-bootstrap");

const initialState = {
  ...connectionInitialState,
  confirmed: false,
  loading: true,
  database: {},
  backup: {},
  submitting: false,
};

function reducer(state, action) {
  const connectionState = connectionReducer(state, action);
  if (connectionState != null) {
    return connectionState;
  }
  switch (action.type) {
    case 'LOAD':
      return { ...state, loading: true };
    case 'LOADED':
      return {
        ...state,
        database: action.database,
        backup: action.backup,
        loading: false,
      };
    case 'CONFIRMED':
      return {
        ...state,
        confirmed: true,
      };
    case 'SUBMITTING':
      return {
        ...state,
        submitting: true,
      };
    case 'SUBMITTED':
      return {
        loading: false,
        backup: state.backup,
        database: state.database,
      }
    default:
      throw new Error('unkonwn action ' + action);
  }
}

function Confirmation({ state, dispatch }) {
  let history = useHistory();
  return <Card.Body>
    <Card.Title>Confirmation</Card.Title>
    <div>
      Please confirm the details of the backup we will restore.
      <Table>
        <tr>
          <th>Created At</th>
          <th>{state.backup.created}</th>
        </tr>
        <tr>
          <th>Size</th>
          <th>{state.backup.backupSize}</th>
        </tr>
        <tr>
        </tr>
      </Table>
      <Button onClick={() => { dispatch({ type: 'CONFIRMED' }); }}>Confirm</Button>
      <Button variant="secondary" onClick={() => history.goBack()}>Back</Button>
    </div>
  </Card.Body>;
}

function DestinationDatabaseEntry({ state, dispatch }) {
  const auth = useAuth0();
  let history = useHistory();
  const form = useForm({ mode: 'onChange' });
  const { handleSubmit, trigger } = form;
  const { backup_id: backupId } = useParams();

  const onSubmit = (formData) => {
    // we can only submit if validation has completed successfully.
    if (!state.connection.valid) {
      trigger();
      return;
    }
    dispatch({ type: 'SUBMITTING' });
    console.log('form data', formData);
    (async () => {
      let submitData = {
        "cursor": {
          "dump_id": backupId,
        },
        "target_database": {
          "hostport": formData.hostport,
          "username": formData.username,
          "password": formData.password,
        },
      };
      let restore = await Post(auth, '/v1/database/' + state.database.id + '/restore', submitData);
      console.log("restore operation", restore);
      history.push('/database/' + state.database.id + '/restore/' + restore.restoreId);
    })();
  }

  let submitButton = <Button
    disabled={false}
    style={{ marginRight: "10px" }}
    type="submit"
    variant="primary">
    Restore
  </Button>;
  if (state.submitting && false) {
    submitButton = <Button variant="primary">
      <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
        style={{ marginRight: "10px" }}
      />
      Adding...
    </Button>;
  }

  return <Card.Body>
    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
      <Card.Title>Destination Database</Card.Title>

      <Row>
        <Col className="control-label" sm="3">
        </Col>
        <Col>
          <Alert variant="warning">
            Note that restoring a backup will overwrite existing data on the destination database.
            This may cause data loss on your destination database. Contact support on the bottom
            right for more information.
          </Alert>
        </Col>
      </Row>
      <ConnectionForm form={form} dispatch={dispatch} state={state} />
      {submitButton}
    </Form>
  </Card.Body>
}

function DatabaseCreateRestore() {
  const { database_id: databaseId, backup_id: backupId } = useParams();

  const auth = useAuth0();
  const [state, dispatch] = useReducer(reducer, initialState);
  console.log('state', state);

  const loadDatabase = (async () => {
    dispatch({ type: 'LOAD' });
    var database = await Get(auth, '/v1/database/' + databaseId)
    console.log('Database, ', database);
    database.reload = loadDatabase;

    var backup = null;
    for (let b in database.backup) {
      if (backupId == database.backup[b].id) {
        backup = database.backup[b];
        break;
      }
    }
    dispatch({ type: 'LOADED', database: database, backup: backup });
  });

  useEffect(() => {
    loadDatabase();
  }, [databaseId]);

  if (state.loading) {
    return (<Loading />)
  }

  if (!state.confirmed) {
    return <Confirmation state={state} dispatch={dispatch} />;
  }
  return <DestinationDatabaseEntry state={state} dispatch={dispatch} />;
}

function DatabaseCreateRestoreLayout(props) {
  return <>
    <h1 style={{ marginTop: "5px" }}>Restore Backup</h1>
    <Card><DatabaseCreateRestore {...props} /></Card>
  </>
}

export default DatabaseCreateRestoreLayout;