export const SkipAuth = false;
export const APIBase = process.env.REACT_APP_BACKEND_HOST;
export const APIIdentifier = process.env.REACT_APP_BACKEND_IDENTIFIER;

export class HTTPError extends Error {
  constructor(message, code, http_body) {
    super(message);
    this.name = "HTTPError";
    this.message = message;
    this.code = code;
    this.http_body = http_body;
  }
}

export async function Patch(auth, path, body) {
  var token = "";
  if (!SkipAuth) {
    token = await auth.getAccessTokenSilently({
      audience: APIIdentifier,
      scope: 'readwrite:database profile email openid',
    });
  }
  let result = await fetch(APIBase + path, {
    method: 'PATCH',
    headers: {
      'Authorization': token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  });
  const content = await result.json();
  if (!result.ok) {
    console.error('Failed to patch', path, result.status, content);
    throw new HTTPError('Failed to patch ' + path, result.status, content);
  }
  return content;
}

export async function Post(auth, path, body) {
  var token = "";
  if (!SkipAuth) {
    token = await auth.getAccessTokenSilently({
      audience: APIIdentifier,
      scope: 'readwrite:database',
    });
  }
  let result = await fetch(APIBase + path, {
    method: 'POST',
    headers: {
      'Authorization': token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  });
  const content = await result.json();
  if (!result.ok) {
    console.error('Failed to post', path, result.status, content);
    throw new HTTPError('Failed to post' + path, result.status, content);
  }
  return content;
}

export async function Get(auth, path) {
  return MakeRequest('GET', auth, path);
}

export async function MakeRequest(method, auth, path, body) {
  var token = "";
  if (!SkipAuth) {
    token = await auth.getAccessTokenSilently({
      audience: APIIdentifier,
      scope: 'readwrite:database',
    });
  }
  let headers = {
    'Authorization': token,
  };
  if (body != undefined) {
    headers['Content-Type'] = 'application/json';
    body = JSON.stringify(body);
  } else {
    body = null;
  }
  let result = await fetch(APIBase + path, {
    method: method,
    headers: headers,
    body: body,
  });
  const content = await result.json();
  if (!result.ok) {
    console.error('Failed to ' + method, path, result.status, content);
    throw new HTTPError('Failed to ' + method + ' ' + path, result.status, content);
  }
  return content;
}
