const { Badge, } = require("react-bootstrap");

export function DatabaseStatus({ database }) {
  if (database.dumpErrorCount > 0) {
    return <Badge pill variant="danger">Backups Failing</Badge>;
  } else if (database.status == "STOPPED_BACKUPS") {
    return <Badge pill variant="warning">Backups Stopped</Badge>;
  } else if (database.status == "DELETED_BACKUPS") {
    return <Badge pill variant="danger">Backups Deleted</Badge>;
  }
  return <Badge pill variant="success">
    Healthy
  </Badge>;
}

export function BackupStatus({ backup }) {
  if (backup.status == "PENDING") {
    return <Badge pill variant="secondary">Pending</Badge>;
  } else if (backup.status == "IN_PROGRESS") {
    return <Badge pill variant="primary">In Progress</Badge>;
  } else if (backup.status == "COMPLETED") {
    return <Badge pill variant="success">Done</Badge>;
  } else if (backup.status == "ERROR") {
    return <Badge pill variant="danger">Error</Badge>;
  }

  return <Badge pill variant="warning">
    Unknown Status
  </Badge>;
}